@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:wght@200&display=swap");
@font-face {
  font-family: "Chronicle";
  src: url("assets/fonts/ChronicleDisp-Light.otf") format("opentype");
}
@font-face {
    font-family: "Chronicle";
    font-weight: bold;
    src: url("assets/fonts/ChronicleDisp-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Gotham";
    src: url("assets/fonts/Gotham-Book.otf") format("opentype");
}
@font-face {
    font-family: "Gotham";
    font-weight: bold;
    src: url("assets/fonts/Gotham-Bold.otf") format("opentype");
}

html {
  overflow-y: scroll;
}
ul {
  margin-left: 16px;
}
ul li {
  font-family: "Avenir", sans-serif;
  font-size: 16px;
}

.MuiInputLabel-outlined {
  transform: translate(12px, 14px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}

.MuiSelect-select {
  padding: 10px !important;
}

.Mui-disabled {
  opacity: 0.8 !important;
}

label.Mui-focused,.MuiFormLabel-filled {
    text-transform: uppercase;
    font-weight: bold;
}

label.MuiFormLabel-filled, label.MuiInputLabel-outlined.Mui-focused {
    font-weight: bold;
    color: #000;
    background-color: white;
    white-space: nowrap;
}

.MuiOutlinedInput-root {
    overflow: hidden
}

a {
    color: black
}

.sticky-wrapper {
    position: relative;
    height: 3rem; /* We need to change this value */
}

.sticky{
    position: sticky !important;
    padding-top:32px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: white !important;
}

.stickyWithHeader{
    position: sticky !important;
    top:80px;
    padding-top:32px;
    left: 0;
    right: 0;
    z-index: 2 !important;
    background-color: white !important;
}

.stickyHeader{
    position: sticky !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3 !important;
}

input:-internal-autofill-selected {
    background: 'white'
}

#schumacher-select-outlined-label {
    color: black;
}
